import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SafePipe } from './safe.pipe';
import { SafeHtmlPipe } from './safe-html.pipe';

@NgModule({
  declarations: [],
  imports: [CommonModule],
  exports: [],
})
export class PipeModule {}
