import { PERMISSION_CODE } from 'src/app/@common/app.permission.constants';
import { ROUTERS } from 'src/app/@common/core.constants';

export const navItems = [
  {
    name: 'Phiếu đề nghị',
    url: ROUTERS.ADMIN.PROPOSALS,
    icon: 'assets/img/svg/icon-proposal.svg',
    attributes: {
      permissions: [
        PERMISSION_CODE.SYSTEM.IMS_FULL_CONTROL,
        PERMISSION_CODE.PROPOSALS.IMS_PROPOSALS_VIEW,
      ],
    },
  },
  {
    name: 'Hợp đồng',
    url: ROUTERS.ADMIN.CONTRACTS,
    icon: 'assets/img/svg/icon-contract.svg',
    attributes: {
      permissions: [
        PERMISSION_CODE.SYSTEM.IMS_FULL_CONTROL,
        PERMISSION_CODE.CONTRACTS.IMS_CONTRACTS_VIEW,
      ],
    },
  },
  {
    name: 'Nhà cung cấp',
    url: ROUTERS.ADMIN.SUPPLIERS,
    icon: 'assets/img/svg/icon-supplier.svg',
    attributes: {
      permissions: [
        PERMISSION_CODE.SYSTEM.IMS_FULL_CONTROL,
        PERMISSION_CODE.SUPPLIERS.IMS_SUPPLIERS_VIEW,
      ],
    },
  },
  {
    name: 'Loại chi phí',
    url: ROUTERS.ADMIN.COST_TYPES,
    icon: 'assets/img/svg/icon-cost.svg',
    attributes: {
      permissions: [
        PERMISSION_CODE.SYSTEM.IMS_FULL_CONTROL,
        PERMISSION_CODE.COST_TYPES.IMS_COST_TYPES_VIEW,
      ],
    },
  },
  {
    name: 'Dự án',
    url: ROUTERS.ADMIN.PROJECTS,
    icon: 'assets/img/svg/icon-cost.svg',
    attributes: {
      permissions: [
        PERMISSION_CODE.SYSTEM.IMS_FULL_CONTROL,
        PERMISSION_CODE.PROJECTS.IMS_PROJECTS_VIEW,
      ],
    },
  },
  // {
  //   name: 'Hóa đơn',
  //   url: ROUTERS.ADMIN.INVOICES,
  //   icon: 'assets/img/svg/icon-invoice.svg',
  //   attributes: {
  //     permissions: [
  //       PERMISSION_CODE.SYSTEM.IMS_FULL_CONTROL,
  //       PERMISSION_CODE.INVOICES.IMS_INVOICES_VIEW,
  //     ],
  //   },
  // },
  {
    name: 'Nhân viên',
    url: ROUTERS.ADMIN.EMPLOYEES,
    icon: 'assets/img/svg/icon-employee-group.svg',
    attributes: {
      permissions: [
        PERMISSION_CODE.SYSTEM.IMS_FULL_CONTROL,
        PERMISSION_CODE.EMPLOYEES.IMS_EMPLOYEES_VIEW,
      ],
    },
  },
  {
    name: 'Phòng ban',
    url: ROUTERS.ADMIN.DEPARTMENTS,
    icon: 'assets/img/svg/icon-nav-02.svg',
    attributes: {
      permissions: [PERMISSION_CODE.SYSTEM.IMS_FULL_CONTROL],
    },
  },
  {
    name: 'Tài khoản',
    url: ROUTERS.ADMIN.ACCOUNTS,
    icon: 'assets/img/svg/icon-nav-03.svg',
    attributes: {
      permissions: [
        PERMISSION_CODE.SYSTEM.IMS_FULL_CONTROL,
        PERMISSION_CODE.ACCOUNTS.IMS_ACCOUNTS_VIEW,
      ],
    },
  },
  {
    name: 'Mã ngân sách',
    url: ROUTERS.ADMIN.FINANCIAL_CODES,
    icon: 'assets/img/svg/icon-nav-04.svg',
    attributes: {
      permissions: [PERMISSION_CODE.SYSTEM.IMS_FULL_CONTROL],
    },
  },
  {
    name: 'Kế hoạch ngân sách',
    url: ROUTERS.ADMIN.BUDGET_PLANS,
    icon: 'assets/img/svg/icon-budget-plan.svg',
    attributes: {
      permissions: [
        PERMISSION_CODE.SYSTEM.IMS_FULL_CONTROL,
        PERMISSION_CODE.BUDGET_PLANS.IMS_BUDGET_PLANS_VIEW,
      ],
    },
  },
  {
    name: 'Báo cáo hàng ngày',
    url: ROUTERS.ADMIN.DAILY_REPORTS,
    icon: 'assets/img/svg/icon-budget-plan.svg',
    attributes: {
      permissions: [
        PERMISSION_CODE.SYSTEM.IMS_FULL_CONTROL,
        PERMISSION_CODE.DAILY_REPORTS.IMS_DAILY_REPORTS_VIEW,
      ],
    },
  },
  {
    name: 'Phân quyền',
    url: ROUTERS.ADMIN.ROLES,
    icon: 'assets/img/svg/icon-nav-04.svg',
    attributes: {
      permissions: [PERMISSION_CODE.SYSTEM.IMS_FULL_CONTROL],
    },
  },
  // {
  //   name: 'Cấu hình',
  //   url: ROUTERS.ADMIN.CONFIG,
  //   icon: 'assets/img/svg/icon-nav-06.svg',
  //   attributes: {
  //     permissions: [PERMISSION_CODE.SYSTEM.IMS_FULL_CONTROL],
  //   },
  // },
];
