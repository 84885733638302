<div class="layout" [class.collapse]="sidenavComp.isCollapseSidebar">
  <app-side-nav #sidenavComp (emitSidebar)="getSidebar($event)" class="side-nav"></app-side-nav>
  <div class="side-content">
    <app-default-header
      #headerComp
      (emitSidebar)="getSidebar($event)"
      class="header"
    ></app-default-header>
    <div class="content">
      <router-outlet></router-outlet>
    </div>
  </div>
</div>

<div id="loading-page" style="display: none">
  <mat-spinner></mat-spinner>
</div>
